.menu div {
  display: none;
}

#menuDIV {
  background-color: var(--color-primary);
}

#menuDIV ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  text-align: center;
  height: 70%;
  text-transform: uppercase;
  font-family: var(--font-bold);
  font-size: 1.1rem;
}

#menuDIV li {
  cursor: pointer;
}

#menuDIV ul a {
  text-decoration: none;
  color: #fff;
}

#mainDIV .slide {
  background-image: url("../images/5suelos/back.jpg");
}

.appointment {
  cursor: pointer;
}

.appointment img {
  display: block;
  margin: 0 auto 10px;
  width: 40px !important;
  height: auto;
}

.schedule {
  font-size: 0.7rem;
  text-align: center;
}

#projects {
  margin-bottom: 20px;
}

.project {
  position: relative;
  text-align: center;
  margin-top: 20px;
}

.project h2 {
  top: 5px;
  left: 5px;
  position: absolute;
  font-size: 1.6rem;
  color: #fff;
  text-align: left;
  text-shadow: 2px 1px 4px #000;
}

.project div {
  width: 100%;
  height: 300px;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.project:nth-child(1) div {
  background-image: url("../montoro/images/mainBG_mobile.jpg");
}

.project:nth-child(2) div {
  background-image: url("../ranchosanantonio/images/mainBG_mobile.jpg");
}

.project:nth-child(3) div {
  background-image: url("../oportunidadorienteags/images/mainBG_mobile.jpg");
}

.project:nth-child(4) div {
  background-image: url("../estadosunidos112/images/mainBG_mobile.jpg");
  margin-bottom: 20px;
}

.project a {
  background-color: orange;
  font-weight: bold;
  color: #fff;
  padding: 20px 40px;
  font-family: var(--font-bold);
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0;
  text-transform: uppercase;
}

@media (min-width: 425px) {
  .menu span {
    display: inline-flex;
  }
}